<template>
  <TableStyleWrapper>
    <TableWrapper class="table-responsive">
      <a-table
        :loading="loadingScheduledTasks"
        :dataSource="scheduledTaskTableData"
        :columns="scheduledTaskTableColumns"
        :pagination="{
          defaultPageSize: 10,
          total: count,
          current: currentPage,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${count} items`,
        }"
        @change="paginationChange"
      />
    </TableWrapper>
  </TableStyleWrapper>
</template>
<script>
import { TableStyleWrapper } from '../../user/style';
import { TableWrapper } from '../../styled';
import { computed, defineComponent } from 'vue';
import {useStore} from "vuex";
import moment from "moment";
import {useRoute, useRouter} from "vue-router";
import {useAbility} from "@casl/vue";

const scheduledTaskTableColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'App',
    dataIndex: 'appType',
    key: 'appType',
  },
  {
    title: 'Logica',
    dataIndex: 'cronExpression',
    key: 'cronExpression',
  },
  {
    title: 'Laatste run op',
    dataIndex: 'lastRunTimestamp',
    key: 'lastRunTimestamp',
  },
  {
    title: 'Acties',
    dataIndex: 'action',
    key: 'action',
    width: '90px',
  },
];
const ScheduledTaskListTable = defineComponent({
  name: 'ScheduledTaskListTable',
  components: { TableStyleWrapper, TableWrapper },
  setup() {
    const { state, dispatch } = useStore();
    const scheduledTasks = computed(() => state.scheduledTask.scheduledTasks);
    const loadingScheduledTasks = computed(() => state.scheduledTask.loadingScheduledTasks);
    const currentPage = computed(() => state.scheduledTask.currentPage);
    const count = computed(() => state.scheduledTask.count);
    const router = useRouter();
    const {matched} = useRoute();
    const {path} = matched[1];
    const { can } = useAbility();
    const handleDelete = (id) => {
      dispatch('deleteScheduledTask', id);
    };
    const handleRun = (id) => {
      dispatch('runScheduledTask', id);
    };
    const handleEdit = (id) => {
      router.push(`${path}/${id}`);
    };
    const scheduledTaskTableData = computed(() =>
        scheduledTasks.value.map((scheduledTask) => {
        const { id, name, lastRunTimestamp,type,appType,cronExpression } = scheduledTask;
          moment.updateLocale(moment.locale(), { invalidDate: "Taak is nog nooit uitgevoerd geweest" })

        return {
          key: id,
          id: id,
          type: <span>{getFriendlyName(type,typeOptions)}</span>,
          appType: <span>{getFriendlyName(appType,appTypeOptions)}</span>,
          cronExpression:cronExpression,
          name: (
            <div class="user-info">
              <figcaption>
                <sdHeading class="user-name" as="h6">
                  {name}
                </sdHeading>
              </figcaption>
            </div>
          ),
          lastRunTimestamp: <span>{moment(lastRunTimestamp).format('DD-MM-YYYY HH:mm:ss')}</span>,
          action: (
              <div className="table-actions">
                {can('view', 'scheduledtask') ? (
                    <a-popconfirm
                        title="Weet je zeker dat je deze taak nu wilt uitvoeren?"
                        ok-text="Ja"
                        cancel-text="Nee"
                        placement="leftTop"
                        onConfirm={() => handleRun(id)}
                    >
                      <sdButton className="btn-icon" type="default" to="#" shape="circle">
                        <sdFeatherIcons type="play" size="16"/>
                      </sdButton>
                    </a-popconfirm>
                ) : ('')}
                {can('view', 'scheduledtask') ? (
                    <sdButton onClick={() => handleEdit(id)} className="btn-icon" to="#" type="default" shape="circle">
                      <sdFeatherIcons type="edit" size="16"/>
                    </sdButton>
                ) : ('')}
                {can('delete', 'scheduledtask') ? (
                    <a-popconfirm
                        title="Weet je het zeker? U kunt dit niet meer terugdraaien!"
                        ok-text="Ja"
                        cancel-text="Nee"
                        placement="leftTop"
                        onConfirm={() => handleDelete(id)}
                    >
                      <sdButton className="btn-icon" type="default" to="#" shape="circle">
                        <sdFeatherIcons type="trash-2" size="16"/>
                      </sdButton>
                    </a-popconfirm>
                ) : ('')}
              </div>
          ),
        };
      }),
    );
    const appTypeOptions = [
      {
        value: 'VALADI',
        key: 'DGB',
      },
      {
        value: 'HEM',
        key: 'HEM',
      },
      {
        value: 'HEMLEAD',
        key: 'HEM Lead',
      }
    ]

    const typeOptions = [
      {
        value: 'SENDORDERS',
        key: 'Orderverzending',
      },
      {
        value: 'SYNCTARIFF',
        key: 'Tarief synchronisatie',
      },
      {
        value: 'SYNCSTATUSSES',
        key: 'Status synchronisatie',
      },
      {
        value: 'SENDFILES',
        key: 'Orderbestand verzending',
      }
    ]
    const getFriendlyName = (type,options) => {

      const index = options.findIndex((t) => {
        if (t.value === type) {
          return true;
        }
      });
      if (index > -1) {
        return options[index].key;
      }

      return '';
    }
    const rowSelection = {
      getCheckboxProps: (record) => ({
        disabled: record.name === 'Disabled scheduledTask', // Column configuration not to be checked
        name: record.name,
      }),
    };

    const paginationChange = async (event) => {
      await dispatch('setCurrentPageScheduledTask', event.current);
      await dispatch('getScheduledTasks', {page: event.current, pageSize: state.sender.pageSize});
    }

    return { scheduledTaskTableColumns, scheduledTaskTableData, rowSelection,loadingScheduledTasks, count, currentPage, paginationChange };
  },
});

export default ScheduledTaskListTable;
</script>
