<template>
 <sdDrawer
  ref="RoleAddRef"
  :form="form"
  btnText="Toevoegen"
  submitBtnText="Opslaan"
  title="Role aanmaken"
  type="submit"
 >
  <FormValidationWrap>
   <a-form ref="formRef" :model="form" :rules="rules" layout="vertical" @finish="CreateRole">
    <a-row :gutter="16">
     <a-col :span="24">
      <a-form-item label="Naam" name="name">
       <a-input v-model:value="form.name" placeholder=""/>
      </a-form-item>
     </a-col>
    </a-row>
    <div
     :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
    >
     <a-button class="btn-signin" html-type="submit" size="large" type="primary"> Opslaan</a-button>
    </div>
   </a-form>
  </FormValidationWrap>
 </sdDrawer>
</template>
<script>
import {defineComponent, reactive, ref} from 'vue';
import {FormValidationWrap} from "../styled";
import {useStore} from "vuex";

const RoleAdd = defineComponent({
 name: 'RoleAdd',
 components: {
  FormValidationWrap,
 },
 setup() {
  const RoleAddRef = ref()
  const formRef = ref();
  const {dispatch} = useStore();
  const form = reactive({
   name: null,
   hasDayLimit: false,
   hasTimeLimit: false,
  });
  const rules = {
   name: [
    {
     required: true,
     message: 'Dit veld is verplicht',
    },
   ],
  };
  const closeDrawer = () => {
   RoleAddRef.value.onClose();
   formRef.value.resetFields();
   dispatch('getRoles')
  }
  const CreateRole = () => {
   dispatch('createRole', {value: form, close: closeDrawer});
  };
  return {
   form,
   rules,
   CreateRole,
   RoleAddRef,
   formRef,
  };
 },
});

export default RoleAdd;
</script>
