<template>
  <CardToolbox>
    <sdPageHeader title="Systeemtaken">
      <template #subTitle>
        <span class="title-counter">{{ count }} {{ count > 0 && count < 2 ? 'systeemtaak' : 'systeemtaken' }}</span>
<!--        <sdAutoComplete :dataSource="searchData" width="100%" placeholder="Zoeken op naam" patterns/>-->
      </template>
      <template #buttons>
        <ScheduledTaskAdd v-if="$can('create', 'scheduledTask')"/>
      </template>
    </sdPageHeader>
  </CardToolbox>
  <Main>
    <a-row :gutter="15">
      <a-col :md="24">
        <ScheduledTaskTable/>
      </a-col>
    </a-row>
  </Main>
</template>
<script>
import ScheduledTaskTable from './component/ScheduledTaskTable';
import {computed, ref, defineComponent, onMounted} from 'vue';
import {useStore} from 'vuex';
import {Main, CardToolbox} from '../styled';
import ScheduledTaskAdd from "./ScheduledTaskAdd";

const ScheduledTaskList = defineComponent({
  name: 'ScheduledTaskList',
  components: {ScheduledTaskAdd, Main, CardToolbox, ScheduledTaskTable},
  data() {
    return {
      showEditDrawer: false,
    }
  },
  setup() {
    const {state, dispatch} = useStore();
    const searchData = computed(() => state.headerSearchData);
    const count = computed(() => state.scheduledTask.count);
    const selectedRowKeys = ref(0);
    const selectedRows = ref(0);

    onMounted(() => {
      dispatch('getScheduledTasks', {page: state.scheduledTask.currentPage, pageSize: state.scheduledTask.pageSize});
    });

    return {
      searchData,
      selectedRowKeys,
      selectedRows,
      count,
    };
  },
});

export default ScheduledTaskList;
</script>
