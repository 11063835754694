<template>
  <TableStyleWrapper>
    <TableWrapper class="table-responsive">
      <a-table
        :loading="loadingRoles"
        :dataSource="roleTableData"
        :columns="roleTableColumns"
        :pagination="{
          defaultPageSize: 10,
          total: count,
          current: currentPage,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${count} items`,
        }"
        @change="paginationChange"
      />
    </TableWrapper>
  </TableStyleWrapper>
</template>
<script>
import { TableStyleWrapper } from '../../user/style';
import { TableWrapper } from '../../styled';
import { computed, defineComponent } from 'vue';
import {useStore} from "vuex";
import moment from "moment";
import {useRoute, useRouter} from "vue-router";
import {useAbility} from "@casl/vue";

const roleTableColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Naam',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Aangemaakt op',
    dataIndex: 'createTimestamp',
    key: 'createTimestamp',
  },
  {
    title: 'Actions',
    dataIndex: 'action',
    key: 'action',
    width: '90px',
  },
];
const RoleListTable = defineComponent({
  name: 'RoleListTable',
  components: { TableStyleWrapper, TableWrapper },
  setup() {
    const { state, dispatch } = useStore();
    const roles = computed(() => state.role.roles);
    const loadingRoles = computed(() => state.role.loadingRoles);
    const currentPage = computed(() => state.role.currentPage);
    const count = computed(() => state.role.count);
    const router = useRouter();
    const {matched} = useRoute();
    const {path} = matched[1];
    const { can } = useAbility();
    const handleEdit = (id) => {
      router.push(`${path}/${id}`);
    };
    const roleTableData = computed(() =>
        roles.value.map((role) => {
        const { id, name, createTimestamp } = role;

        return {
          key: id,
          id: id,
          name: (
            <div class="user-info">

              <figcaption>
                <sdHeading class="user-name" as="h6">
                  {name}
                </sdHeading>
              </figcaption>
            </div>
          ),
          createTimestamp: <span>{moment(createTimestamp).format('DD-MM-YYYY HH:mm:ss')}</span>,
          action: (
              <div className="table-actions">
                {can('view', 'role') && name !== 'superadmin' ? (
                    <sdButton onClick={() => handleEdit(id)} className="btn-icon" to="#" type="default" shape="circle">
                      <sdFeatherIcons type="edit" size="16"/>
                    </sdButton>
                ) : ('')}
              </div>
          ),
        };
      }),
    );

    const rowSelection = {
      getCheckboxProps: (record) => ({
        disabled: record.name === 'Disabled role', // Column configuration not to be checked
        name: record.name,
      }),
    };

    const paginationChange = async (event) => {
      await dispatch('setCurrentPageRole', event.current);
      await dispatch('getRoles', {page: event.current, pageSize: state.sender.pageSize});
    }

    return { roleTableColumns, roleTableData, rowSelection,loadingRoles, count, currentPage, paginationChange };
  },
});

export default RoleListTable;
</script>
